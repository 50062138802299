import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const OfficialWebsite = () => import('@/views/OfficialWebsite/OfficialWebsite.vue')

export const constantRoutes = [

    {
        path: '/',
        component: OfficialWebsite, // 作业票
        meta: { 
            toolbar: true,
            keepAlive: true,  
        },
    }, {
        path: '*', 
        redirect: '/' 
    }
] 

const createRouter = () =>
    new Router({
        mode: 'hash', // require service support
        // scrollBehavior: () => ({ 
        //     y: 0
        // }),
        routes: constantRoutes
    })
const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

export default router