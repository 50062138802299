import axios from 'axios'
import { Message } from 'element-ui'
import qs from 'qs'

// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // baseURL: 'https://gw.sharingthecar.com',
  baseURL: '/gw',
  transformRequest: [
    function(data, headers) {
      if (headers['Content-Type'] === 'application/x-www-form-urlencoded') {
        // 把一个参数对象格式化为一个字符串
        return qs.stringify(data)
      } else if (
        headers['Content-Type'] === 'multipart/form-data;charset=UTF-8'
      ) {
        return data
      } else {
        headers['Content-Type'] = 'application/json'
      }
      return JSON.stringify(data)
    }
  ]
  // withCredentials:true
  // timeout: 5000 // request timeout
})
axios.defaults.withCredentials = true
axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'
// axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // console.log("config",config)
    return config
  },
  (error) => {
    // 请求前出错做些什么
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data
    // console.log(response)
    if (res.code == 0) {
      // 请求成功后将数据返回
      return Promise.resolve(res.data)
    }
    if (res.code == 1) {
      // 请求出错将错误信息返回
      // console.log('执行了request中的 错误消息')
      // Message.error(res.message||'系统出现错误，请稍后重试')

      return Promise.reject(res.message)
    }
    if (res.code == 2) {
      // 用户未登录
      Message.error('您还没有登录或者登录凭证过期')
      return Promise.reject(res.message)
    } else {
      return Promise.reject(res.message)
    }
    // if the custom code is not 20000, it is judged as an error.
    // if (res.code !== 20000) {
    //   Message({
    //     message: res.message || 'Error',
    //     type: 'error',
    //     duration: 5 * 1000
    //   })

    // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    //   if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
    //     // to re-login
    //     MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
    //       confirmButtonText: 'Re-Login',
    //       cancelButtonText: 'Cancel',
    //       type: 'warning'
    //     }).then(() => {
    //       store.dispatch('user/resetToken').then(() => {
    //         location.reload()
    //       })
    //     })
    //   }
    //   return Promise.reject(new Error(res.message || 'Error'))
    // } else {
    //   return res
    // }
  },
  (error) => {
    console.log('err' + error) // for debug
    Message.error(error.message)
    return Promise.reject(error)
  }
)

export default service
