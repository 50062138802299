<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>

<script>
export default {
  name: "App",
  mounted() {
    this.getUrlParm(window.location.href);
  },
  methods: {
    getUrlParm(url) {
      // 通过 ? 分割获取后面的参数字符串
      let urlStr = url.split("?")[1];
      if (!urlStr) return null;
      // 创建空对象存储参数
      let obj = {};
      // 再通过 & 将每一个参数单独分割出来
      let paramsArr = urlStr.split("&");
      for (let i = 0, len = paramsArr.length; i < len; i++) {
        // 再通过 = 将每一个参数分割为 key:value 的形式
        let arr = paramsArr[i].split("=");
        obj[arr[0]] = arr[1];
      }
      if (obj.s) {
        this.$api.config
          .gets({
            s: obj.s,
          })
          .then(() => {})
          .catch(() => {});
      }
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center;
  color: #2c3e50; */
  /* margin-top: 60px; */
}
body {
  padding: 0;
  margin: 0;
  
}
@media screen and (min-width: 768px) {
    body {
        min-width: 1440px;
    }
}

div {
  box-sizing: border-box;
}
</style>
