// config 全局配置相关api
import request from '@/utils/request'
// 获取配置
export function getPick() {
  return request({
    url: '/common/getPick',
    method: 'post'
  })
}
export function getDropOff() {
  return request({
    url: '/common/getDropOff',
    method: 'post'
  })
}
export function privacyPolicy() {
  return request({
    url: '/h5/privacyPolicy',
    method: 'post'
  })
}
export function gets(date) {
  return request({
    url: `/s?s=${date.s}`,
    method: 'get'
  })
}